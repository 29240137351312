<template>
  <ShablykinoVillage />
</template>

<script>
import ShablykinoVillage from '@/components/ShablykinoVillage.vue';
export default {
  name: 'App',
  components: {
    ShablykinoVillage
  }
};
</script>

<style>
body {
  background-color: #414f33;
  /* height: 100vh; */
}
</style>
