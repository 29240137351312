<template>

  <div class="shablykino-village">
    <header class="header">
      <LogoSmall class="logo_small" />
      <LogoBig class="logo" />

      <nav class="navigation">
        <div class="nav_links">
          <a href="#about" class="nav-link" @click="scrollToSection">О нас</a>
          <a href="#houses" class="nav-link" @click="scrollToSection">Наши домики</a>
        </div>
        <a class="nav-link account-btn" href="https://semidvorieshablukino.ru/shablykino/auth">Личный кабинет</a>
      </nav>
    </header>

    <section class="hero">
      <img src="@/assets/images/bg-main.jpg" alt="Shablykino Village Landscape" class="hero-image" />
      <div class="hero-content">
        <div class="menu">
          <h1 class="hero-title">Аренда домиков</h1>
          <h2 class="hero-subtitle">Шаблыкино Village</h2>
        </div>
        <button class="book-btn"><a href="https://semidvorieshablukino.ru/shablykino/rent">Забронировать</a></button>
      </div>
    </section>

    <main class="main-content">
      <section id="about" class="about-section">
        <div class="about-content">
          <h2 class="section-title">О нас</h2>
          <p class="about-description">
            Шаблыкино Village - это не только аренда уютных домиков, в которых можно отдыхать с семьей, друзьями или
            чтоб съездить вдвоем на выходные. Это также интересная и красивая местность. Любителям погулять будет
            приятно знать, что здесь вы найдете необычайные по своей красоте пейзажи, а также старинные церкви. Данное
            место придаст сил или же станет точкой спокойствия.
          </p>
        </div>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/58b525d8eeb3a2591190edf4d15daefbd70bf3edc5bae4572b817d5bad133b67?apiKey=d8b4e1ffc55740b5ab5f50fa02a7346d&&apiKey=d8b4e1ffc55740b5ab5f50fa02a7346d"
          alt="Shablykino Village Scenery" class="about-image" />
      </section>

      <section class="features">
        <table>
          <caption>Что мы предлагаем?</caption>
          <tr>
            <td>
              <h3>Большая территория</h3>
              <p>50 Гектаров живописных полей</p>
            </td>
            <td>
              <h3>Животные</h3>
              <p>Можно взять своего питомца с собой (с учетом правил проживания)</p>
            </td>
            <td>
              <h3>Детская площадка</h3>
              <p>Для активных детей есть батут и качели</p>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Общая костровая зона</h3>
              <p>Интересные истории и знакомства под треск костра</p>
            </td>
            <td>
              <h3>Просмотр сериалов и фильмов</h3>
              <p>Есть Smart TV</p>
            </td>
            <td>
              <h3>Гриль зона у каждого домика</h3>
              <p>Мангал, гриль</p>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Настольные игры</h3>
              <p>В каждом домике вы найдете игры и для семьи, и для веселой компании</p>
            </td>
            <td>
              <h3>Горячий чан</h3>
              <p>Банный чан, расположенный под открытым небом на территории за дополнительную плату</p>
            </td>
            <td>
              <h3>Прогулки по лесу</h3>
              <p>Грибникам на заметку</p>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Wi-Fi</h3>
              <p>Во всех домиках стабильный Wi-Fi, а также хорошо ловит мобильный интернет</p>
            </td>
            <td>
              <h3>Дома для аллергиков</h3>
              <p>В такие домики мы никогда не заселяем с животными</p>
            </td>
            <td>
              <h3>Парковка</h3>
              <p>Парковочное место у каждого дома</p>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Настольный теннис</h3>
              <p>На территории Шаблыкино Village можно поиграть в настольный теннис</p>
            </td>
            <td>
              <h3>Футбол</h3>
              <p>Можно поиграть в футбол на приятной траве</p>
            </td>
            <td></td>
          </tr>
        </table>
      </section>
      <section id="houses" class="houses-section">
        <h2 class="section-title">Наши активности</h2>
        <p class="section-subtitle">Мы предлагаем следующие типы отдыха</p>
        <div class="house-cards">
          <div class="house-card" v-for="(activity, activityIndex) in activities" :key="activityIndex">
            <div class="house-image-container">
              <img :src="activity.images[activity.currentImageIndex]" :alt="'Activity ' + (activityIndex + 1)"
                class="house-image" @click="openModalAct(activityIndex, activity.currentImageIndex)" />
              <div class="image-navigation">
                <button @click.stop="prevActImage()" class="nav-button prev">&#10094;</button>
                <button @click.stop="nextActImage()" class="nav-button next">&#10095;</button>
              </div>
            </div>
            <div class="thumbnail-container">
              <img v-for="(image, imageIndex) in activity.images" :key="imageIndex" :src="image"
                :class="['thumbnail', { active: imageIndex === activity.currentImageIndex }]"
                @click="setCurrentActImage(activityIndex, imageIndex)" />
            </div>
            <p class="house-description" v-html="activity.description"></p>
          </div>
        </div>
      </section>

      <section id="houses" class="houses-section">
        <h2 class="section-title">Наши домики</h2>
        <p class="section-subtitle">Мы предлагаем три типа размещения</p>
        <div class="house-cards">
          <div class="house-card" v-for="(house, houseIndex) in houses" :key="houseIndex">
            <div class="house-image-container">
              <img :src="house.images[house.currentImageIndex]" :alt="'House Type ' + (houseIndex + 1)"
                class="house-image" @click="openModal(houseIndex)" />
              <div class="image-navigation">
                <button @click.stop="prevHouseImage(houseIndex)" class="nav-button prev">&#10094;</button>
                <button @click.stop="nextHouseImage(houseIndex)" class="nav-button next">&#10095;</button>
              </div>
            </div>
            <div class="thumbnail-container">
              <img v-for="(image, imageIndex) in house.images" :key="imageIndex" :src="image"
                :class="['thumbnail', { active: imageIndex === house.currentImageIndex }]"
                @click="setCurrentHouseImage(houseIndex, imageIndex)" />
            </div>
            <p class="house-description" v-html="house.description"></p>
            <p class="house-price">
              {{ house.price }}
            </p>
          </div>
        </div>
      </section>

      <div class="modal" v-if="showModal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <button class="nav-button prev" @click="prevImage">
            <svg viewBox="0 0 24 24">
              <path d="M15 6l-6 6 6 6" />
            </svg>
          </button>
          <button class="nav-button next" @click="nextImage">
            <svg viewBox="0 0 24 24">
              <path d="M9 6l6 6-6 6" />
            </svg>
          </button>
          <img :src="currentImage" ref="modalImage" class="modal-image" @wheel.prevent="handleWheel" />
          <div class="zoom-controls">
            <button @click="zoomIn">+</button>
            <button @click="zoomOut">-</button>
          </div>
        </div>
      </div>

      <div class="modal" v-if="showActivityModal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <button class="nav-button prev" @click="prevActImage">
            <svg viewBox="0 0 24 24">
              <path d="M15 6l-6 6 6 6" />
            </svg>
          </button>
          <button class="nav-button next" @click="nextActImage">
            <svg viewBox="0 0 24 24">
              <path d="M9 6l6 6-6 6" />
            </svg>
          </button>
          <img :src="currentActivityImage" ref="modalImage" class="modal-image" @wheel.prevent="handleWheel" />
          <div class="zoom-controls">
            <button @click="zoomIn">+</button>
            <button @click="zoomOut">-</button>
          </div>
        </div>
      </div>

      <section class="location-section">
        <h1>Как до нас добраться?</h1>
        <div class="location-content">
          <div>
            <div class="content-text">
              <h2>Своим ходом</h2>
              <p class="location-description">
                На электричке от Ярославского вокзала до станции Струнино. От неё до Шаблыкино ходит рейсовый автобус
                пять
                раз в день. Либо можно воспользоваться недорогим такси
              </p>
            </div>
            <div class="content-text">
              <h2>На машине</h2>
              <p>
                Шаблыкино Village находится во Владимирской области, недалеко от города Струнино. Из центра Москвы на
                машине
                составляет <b>70-80 минут</b>
              </p>
            </div>
          </div>
          <iframe class="map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A5529ec98ec18193562c0b3d5f895292ad012770544abb603d52ce3a13ca4254b&amp;source=constructor"
            frameborder="0"></iframe>
        </div>
      </section>
    </main>
  </div>
  <footer>
    <div class="content">
      <div class="info">
        <h3>Контакты:</h3>
        <div class="info-content">
          <a href="tel: +79619840000" style="white-space: nowrap;">8 (961) 984-00-00</a>
        </div>
      </div>
      <div class="info">
        <h3>Адрес:</h3>
        <div class="info-content">
          <a style="max-width: 550px;" href="https://yandex.ru/maps/-/CDcyVKlJ">
            Владимирская область, Александровский район, муниципальное образование Каринское, деревня Шаблыкино, ДПК
            Шаблыкино-3

          </a>
        </div>
      </div>
      <div class="info documnets-info">
        <h3>Документы:</h3>
        <div class="info-content">
          <a href="/Пользовательское соглашение.docx">
            Пользовательское соглашение
          </a>
          <a href="/Правила пребывания и оплаты аренды домов в комплексе Шаблыкино-Village.docx">
            Правила пребывания и оплаты аренды домов
          </a>
          <a href="/Публичная оферта.docx">
            Публичная оферта
          </a>
        </div>
      </div>
      <div class="info billing-info">
        <h3>Банковские реквизиты:</h3>
        <div class="info-content">
          <p>ООО «Компания «Центр-2000»</p>
          <p>БИК: 044525225</p>
          <p>КОРР. СЧЁТ: 30101810400000000225</p>
          <p>НОМЕР СЧЕТА: 40702810838000057798</p>
          <p>ИНН: 7709352076</p>
        </div>
      </div>
    </div>
    <div class="logo-info">
      <a href="http://centre-2000.ru/" class="footer-logo">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/db649bbe0bdec88d9b3fd91bd4d02e48e228eb0dbabdbab3fcb187e2446f6195?apiKey=d8b4e1ffc55740b5ab5f50fa02a7346d&&apiKey=d8b4e1ffc55740b5ab5f50fa02a7346d"
          alt="Shablykino Village Logo" />
      </a>
      <div class="social-networks">
        <a href="https://vk.com/shablikino" target="_blank">
          <img src="@/assets/images/VK Logo White.png" alt="VK Logo">
        </a>
        <a href="https://t.me/shablikino_villagebot" target="_blank">
          <img src="@/assets/images/Telegram Logo White.png" alt="Telegram Logo">
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
import LogoSmall from "@/components/LogoSmall.vue";
import LogoBig from "@/components/LogoBig.vue";
export default {
  data() {
    return {
      showModal: false,
      showActivityModal: false,
      currentHouseIndex: 0,
      currentActivityIndex: 0,
      currentImageIndex: 0,
      houses: [
        {
          images: [
            require('@/assets/images/house1-side1.jpg'),
            require('@/assets/images/house1-side2.jpg'),
            require('@/assets/images/house1-side3.jpg'),
            require('@/assets/images/house1-side4.jpg'),
            require('@/assets/images/house1-side5.jpg'),
            require('@/assets/images/house1-side6.jpg'),
            require('@/assets/images/house1-side7.jpg'),
            require('@/assets/images/house1-side8.jpg'),
            require('@/assets/images/plan30.jpeg'),

          ],
          description: 'Площадь: 30 кв.м.<br>Спальня и кухня-гостинная<br>Размещение: до 3 чел.',
          price: '4000 руб/сутки'
        },
        {
          images: [
            require('@/assets/images/house2-side1.jpg'),
            require('@/assets/images/house2-side2.jpg'),
            require('@/assets/images/house2-side3.jpg'),
            require('@/assets/images/house2-side4.jpg'),
            require('@/assets/images/house2-side5.jpg'),
            require('@/assets/images/house2-side6.jpg'),
            require('@/assets/images/house2-side7.jpg'),
            require('@/assets/images/plan40.jpeg')
          ],
          description: 'Площадь: 40 кв.м.<br>2 спальни и кухня-гостинная<br>Размещение: до 4 чел.',
          price: '5000 руб/сутки'
        },
        {
          images: [
            require('@/assets/images/house3-side1.jpg'),
            require('@/assets/images/house3-side2.jpg'),
            require('@/assets/images/house3-side3.jpg'),
            require('@/assets/images/house3-side4.jpg'),
            require('@/assets/images/house3-side5.jpg'),
            require('@/assets/images/house3-side6.jpg'),
            require('@/assets/images/house3-side7.jpg'),
            require('@/assets/images/house3-side8.jpg'),
            require('@/assets/images/house3-side9.jpg'),
            require('@/assets/images/house3-side10.jpg'),
            require('@/assets/images/plan55.jpeg')
          ],
          description: 'Площадь: 55 кв.м.<br>2 спальни и просторная кухня-гостинная<br>Размещение: до 6 чел.',
          price: '6500 руб/сутки'
        }
      ],
      activities: [
        {
          images: [
            require('@/assets/images/swing1.jpg'),
            require('@/assets/images/swing3.jpg'),
            require('@/assets/images/swing2.jpg'),
            require('@/assets/images/swing5.jpg'),
            require('@/assets/images/swing4.jpg'),

          ],
          description: 'Батуты и качели',
          currentImageIndex: 0
        },
        {
          images: [
            require('@/assets/images/chan1.jpg'),
            require('@/assets/images/chan2.jpg'),
            require('@/assets/images/chan3.jpg'),
            require('@/assets/images/chan4.jpg'),
            require('@/assets/images/chan5.jpg'),
          ],
          description: 'Чан и костровая зона',
          currentImageIndex: 1
        },
        {
          images: [
            require('@/assets/images/nature1.jpg'),
            require('@/assets/images/nature2.jpg'),
            require('@/assets/images/nature3.jpg'),
            require('@/assets/images/nature4.jpg'),
            require('@/assets/images/nature5.jpg'),

          ],
          description: 'Природа',
          currentImageIndex: 2
        }
      ]
    }
  },
  components: {
    LogoSmall,
    LogoBig
  },
  methods: {
    scrollToSection(event) {
      event.preventDefault();
      const targetId = event.currentTarget.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    prevHouseImage(houseIndex) {
      const house = this.houses[houseIndex];
      house.currentImageIndex = (house.currentImageIndex - 1 + house.images.length) % house.images.length;
    },

    nextHouseImage(houseIndex) {
      const house = this.houses[houseIndex];
      house.currentImageIndex = (house.currentImageIndex + 1) % house.images.length;
    },

    setCurrentHouseImage(houseIndex, imageIndex) {
      this.houses[houseIndex].currentImageIndex = imageIndex;
    },
    openModal(houseIndex, imageIndex = 0) {
      this.currentHouseIndex = houseIndex;
      this.currentImageIndex = imageIndex;
      this.showModal = true;
    },
    openModalAct(activityIndex, imageIndex = 0) {
      this.currentActivityIndex = activityIndex;
      this.currentImageIndex = imageIndex;
      this.showActivityModal = true;
    },
    closeModal() {
      this.showActivityModal = false;
      this.showModal = false;
    },
    prevActImage() {
      const images = this.activities[this.currentActivityIndex].images;
      this.currentImageIndex = (this.currentImageIndex - 1 + images.length) % images.length;
    },
    nextActImage() {
      const images = this.activities[this.currentActivityIndex].images;
      this.currentImageIndex = (this.currentImageIndex + 1) % images.length;
    },
    setCurrentActImage(activityIndex, imageIndex) {
      this.activities[activityIndex].currentImageIndex = imageIndex;
    },
    prevImage() {
      const images = this.houses[this.currentHouseIndex].images;
      this.currentImageIndex = (this.currentImageIndex - 1 + images.length) % images.length;
    },
    nextImage() {
      const images = this.houses[this.currentHouseIndex].images;
      this.currentImageIndex = (this.currentImageIndex + 1) % images.length;
    },
    zoomIn() {
      const img = this.$refs.modalImage;
      const currentScale = img.style.transform ? parseFloat(img.style.transform.replace('scale(', '').replace(')', '')) : 1;
      img.style.transform = `scale(${currentScale + 0.1})`;
    },
    zoomOut() {
      const img = this.$refs.modalImage;
      const currentScale = img.style.transform ? parseFloat(img.style.transform.replace('scale(', '').replace(')', '')) : 1;
      if (currentScale > 1) {
        img.style.transform = `scale(${currentScale - 0.1})`;
      }
    },
    handleWheel(event) {
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    }
  },
  computed: {
    currentImage() {
      return this.houses[this.currentHouseIndex].images[this.currentImageIndex];
    },
    currentActivityImage() {
      return this.activities[this.currentActivityIndex].images[this.currentImageIndex];
    }
  },
  created() {
    this.houses.forEach(house => {
      house.currentImageIndex = 0;
    });
  }
}
</script>
<style scoped>
:root {
  --primary-color: #414f33;
  --secondary-color: #e2dfd6;
  --text-color: #252d1d;
  --background-color: #f5f3ed;
}

.logo_small {
  display: none;
}

.nav-links {
  display: contents;
}

.header {
  font-family: "TT Norms" !important;
  background-color: #414f33;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 33px;
  color: #e2dfd6;
  position: fixed;
  top: 0;
  left: 0;
  width: -webkit-fill-available;
  z-index: 1000;
}

.shablykino-village {
  background-color: #f5f3ed;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.nav-links {
  gap: 30px;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

.logo {
  width: 320px;
  max-width: 100%;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 38px;
  font-weight: bold;
}

nav>a {
  font-size: 18px;
}

.nav-link {
  color: inherit;
  text-decoration: none;
}

.account-btn {
  border-radius: 40px;
  background-color: #e2dfd6;
  color: #252d1d;
  padding: 11px 39px;
  border: solid 3px #252d1d;
}

.hero {
  position: relative;
  height: calc(100vh - 40px);
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 50px;
  color: #e2dfd6;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}

.hero-content {
  text-align: center;
  color: #e2dfd6;
  padding: 20px;
  padding-bottom: 5%;
  border-radius: 10px;
  /* position: ; */
  z-index: 15;
}

.hero-title {
  font-family: "TT Drugs" !important;
  font-size: 64px;
  font-weight: 800;
  margin: 0;
  color: white;
}

.hero-subtitle {
  font-family: "TT Norms" !important;
  font-size: 128px;
  font-weight: 600;
  margin: 10px 0;
  color: white;
}

.book-btn {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  padding: 15px 30px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border: solid 3px white;
}

.book-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.main-content {
  max-width: 1037px;
  margin: 62px auto 0;
  padding: 0 20px;
}

.section-title {
  font-family: "TT Drugs" !important;
  color: #252d1d;
  font-size: 64px;
  margin-bottom: 0;
  margin-top: 0;
}

.about-section {
  display: flex;
  gap: 20px;
}

.about-content {
  flex: 1;
  max-width: 100%;
  padding-right: 20px;
}

.about-description {
  color: #000;
  font-size: 32px;
  margin-top: 52px;
  text-align: justify;
  width: 99%;
  font-weight: 100;
  line-height: 1.5;
}

.about-image {
  flex: 1;
  object-fit: cover;
  height: 100%;
  width: 450px;
  margin-top: 5rem;
}

.features {
  margin: 0 auto;
  margin-top: 52px;
}

.house-image-container {
  position: relative;
  overflow: hidden;
}

.image-navigation {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.thumbnail-container {
  display: flex;
  margin-top: 10px;
  overflow-x: auto;

  @media (min-width: 1300px) {
    display: none;
  }
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.6;
}

.thumbnail.active {
  opacity: 1;
  border: 2px solid #414f33;
}

table {
  width: 100%;
  border-collapse: collapse;
}

caption {
  font-family: "TT Drugs" !important;
  font-size: 64px;
  text-align: left;
  margin-bottom: 50px;
}

td {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 0 28px 15px 28px;
  text-align: left;
  vertical-align: top;
  width: 350px;
}

td:first-child {
  border-left: none;
  /* width: 340px; */
  padding-top: 0;
}

td:last-child {
  border-right: none;
  width: 303px;
  padding-top: 0;
}

td h3 {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 28px;
}

td p {
  color: black;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
}

.houses-section {
  margin-top: 52px;
}

.houses-section>p,
.house-description,
.house-price {
  font-size: 24px;
}

.house-price {
  margin: auto 5px 0 5px;
  color: #252d1d;
  font-weight: bold;
  font-size: 32px;
}

.section-subtitle {
  color: #000;
  font-family: "Alegreya Sans SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.house-cards {
  margin-top: 28px;
  display: flex;
  gap: 8px;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.house-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.house-image {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}

.house-description {
  margin: 40px 5px 20px 5px;
  color: #252d1d;
  font-weight: 500;
}

.location-map {
  flex: 1;
  border-radius: 40px;
}

a {
  text-decoration: none;
  color: #fff;
}


.house-image-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-height: 231.33px;
  height: auto;

  @media (max-width: 1300px) {
    max-height: none;
  }
}

.house-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.house-image-container:hover .house-overlay {
  opacity: 1;
}

.zoom-icon {
  color: white;
  font-size: 2rem;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: 80vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.close {
  position: absolute;
  top: -10px;
  right: 10px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 769px) {
  .close {
    top: 70px !important;
  }
}

.zoom-controls {
  position: absolute;
  bottom: -50px;
  right: 0;
}

.zoom-controls button {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}

.nav-button svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #333;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.nav-button.prev svg {
  margin-right: 2px;
}

.nav-button.next svg {
  margin-left: 2px;
}

tr,
.house-description {
  font-family: 'Alegreya Sans SC', sans-serif;
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .about-image {
    height: unset;
    margin: 0;
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .main-content {
    max-width: 90%;
  }

  caption {
    text-align: center;
  }

  .hero-title {
    font-size: 48px;
  }

  .nav-links {
    opacity: 0;
  }

  .hero-subtitle {
    font-size: 96px;
  }

  .about-section {
    flex-direction: row;
    height: auto;
  }

  .about-image {
    width: 50%;
    height: auto;
    /* margin-top: 0; */
  }

  caption,
  .section-title {

    text-align: center;
  }

  .houses-grid {
    display: grid;
  }

  .house-description {
    font-size: 36px;
  }

  .house-card {
    flex-basis: calc(50% - 10px);
    margin-bottom: 20px;
  }

  table {
    width: 100%;
  }

  td {
    display: table-cell;
    width: 33.33%;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  td:first-child {
    border-left: none;
  }

  td:last-child {
    border-right: none;
  }

  .section-subtitle {
    display: none;
  }
}

@media (max-width: 900px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
  }

  caption {
    text-align: center;
  }

  .logo {
    display: none;
  }

  .logo_small {
    display: block;
    height: 30px;
    width: auto;
  }

  .navigation {
    flex-direction: row;
    gap: 15px;
  }

  .nav_links {
    display: none;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-subtitle {
    font-size: 72px;
  }

  .about-section {
    flex-direction: column;
  }

  .about-image {
    display: none;
  }

  .section-title {
    font-size: 48px;
    text-align: center;
  }

  .logo_small {
    width: 57px;
    height: 55px;
  }

  .main-content {
    margin: 62px 0 0 0;
  }

  .menu {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: column-reverse;
  }

  .about-description {
    font-size: 24px;
    /* text-align: center; */
  }

  .features table {
    display: block;
  }

  .about-content {
    padding: 0;
  }

  td {
    display: block;
    width: 100%;
    border: none;
    padding: 11px 0;
  }

  .houses-grid {
    flex-direction: column;
  }

  .house-card {
    margin-bottom: 30px;
  }

  .about-section {
    height: auto;
  }

  iframe {
    width: 100%;
    height: 300px;
    border-radius: 40px;
  }


  .section-subtitle {
    display: none;
  }
}

@media (max-width: 600px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .about-content {
    text-align: center;
    padding: 0;
  }

  .about-section>img,
  .section-subtitle {
    display: none;
  }

  .navigation {
    /* margin-top: 15px; */
    flex-direction: column;
    gap: 15px;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-subtitle {
    font-size: 60px;
  }

  .hero-content {
    left: -15px;
  }

  .section-title {
    font-size: 56px;
    text-align: center;
  }

  .about-description {
    font-size: 26px;
  }

  iframe {
    width: 100%;
  }

  caption {
    font-size: 56px;
    text-align: center;
    margin-bottom: 0;
  }

  td h3 {
    font-size: 32px;
  }

  td p {
    font-size: 16px;
  }

  .main-content,
  .section-title {
    margin-top: 29px;
  }

  .about-description {
    margin: 0;
    margin: 25px 0 29px 0;
  }

  .features {
    margin-top: 29px;
  }

  .houses-section {
    margin: 0;

  }

  .section-title {
    margin-bottom: 29px;
  }

  caption {
    margin-bottom: 18px;
  }

  td,
  td:first-child,
  td:last-child {
    border: none;
    margin: 0;
    width: 100%;
  }

  td {
    display: block;
    width: 100%;
    border: none;
    padding: 11px 0;
  }

  td>h3,
  td:first-child>h3,
  td:last-child>h3 {
    margin-top: 0;
  }

  .house-description {
    font-size: 24px;
  }

  .house-price {
    font-size: 32px;
    margin: 0;
  }


  .modal-content {
    width: 95vw;
    height: 95vh;
  }

  .nav-button {
    width: 30px;
    height: 30px;
  }

  .nav-button svg {
    width: 18px;
    height: 18px;
  }

  .menu {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
  }

  .nav_links,
  .logo {
    display: none;
  }

  .logo_small {
    display: block;
  }

  .account-btn {
    padding: 20px;
    border-radius: 40px;
  }
}

@media (min-width: 600px) {
  .nav_links {
    display: flex;
    gap: 30px;
  }

  .nav_links a {
    font-size: 18px;
  }
}

@media (min-width: 600px) and (max-width: 1300px) {
  .hero-title {
    font-size: 48px;
  }

  caption {
    text-align: center;
  }

  .hero-subtitle {
    font-size: 84px;
  }

  .section-title {
    font-size: 64px;
  }

  .about-description {
    font-size: 26px;
  }

  td h3 {
    font-size: 32px;
  }

  td p {
    font-size: 16px;
  }

  .house-description {
    font-size: 36px;
  }

  iframe {
    width: 100%;
  }

  .house-price {
    font-size: 48px;
  }

  td,
  td>h3 {
    margin-top: 0;
    padding-top: 0;
  }


  table:first-child {
    text-align: center;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .hero-title {
    font-size: 56px;
  }

  caption {
    caption-side: top;
    text-align: center;
  }



  .hero-subtitle {
    font-size: 108px;
  }

  .section-title {
    font-size: 64px;
  }

  .about-description {
    font-size: 34px;
  }

  td h3 {
    font-size: 24px;
  }

  td p {
    font-size: 18px;
  }

  .house-description {
    font-size: 34px;
  }

  .house-price {
    font-size: 40px;
  }

  .book-btn {
    border-radius: 100px;
  }
}

@media (max-width: 599px) {
  .nav_links {
    display: none;
  }

  caption {
    text-align: center;
  }
}

@media (max-width: 1400px) and (min-width: 900px) {
  .houses-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .house-card {
    flex-basis: calc(50% - 20px);
    /* Задает ширину карточки как половину ширины контейнера минус отступ */
    margin-bottom: 20px;
    /* Добавляет отступы между карточками */
    box-sizing: border-box;

  }
}

.location-section {
  margin-top: 52px;
  font-family: 'Roboto';
  color: black;
  padding: 0 0 75px 0;
}

.location-section h1 {
  font-family: "TT Drugs" !important;
  margin: 0;
  color: #252D1D;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 4rem;
}

.location-section .location-content {
  margin-top: 32px;
  gap: 38px;
  display: flex;
}

.location-section .location-content .map {
  max-width: 600px;
  width: 100%;
  border-radius: 40px;
}

.location-section .location-content .content-text {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.location-section .location-content .content-text:first-child {
  margin-top: 0;
}

.location-section .location-content .content-text h2 {
  margin: 0;
  color: #000;
  font-family: "Alegreya Sans SC";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.location-section .location-content .content-text p {
  margin: 0;
  max-width: 494px;
  color: #252D1D;
  font-family: "Alegreya Sans SC";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 1439px) {
  .location-section .location-content {
    flex-direction: column;
    align-items: center
  }

  .location-section .location-content .content-text p {
    font-size: 1.5rem;
  }

  .location-section .location-content .content-text p {
    font-size: 1.25rem;
    max-width: none;
  }

  .location-section .location-content .map {
    min-height: 333px;
  }
}

@media (max-width: 767px) {
  .container-fluid {
    padding: 0 22px;
  }

  .location-section h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .location-section .location-content .content-text p {
    font-size: 1.5rem;
  }

  .location-section .location-content .content-text p {
    font-size: 1.25rem;
    max-width: none;
  }

  .location-section .location-content .content-text {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .location-section .location-content .map {
    min-height: 220px;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 40px 25px;
  background-color: #414f33;
  color: white;
}

footer .content .info {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

footer .content .info h3 {
  margin: 0;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
}

footer .content {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
}

footer .content .info .info-content {
  display: flex;
  flex-direction: column;
}

footer .content .info .info-content a,
p {
  margin: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  text-decoration: none;
}

footer .content .documnets-info a {
  font-size: 16px !important;
  text-decoration: underline !important;
}

footer .content .billing-info p {
  font-size: 16px !important;
}

footer .logo-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  height: fit-content;
}

footer .logo-info .footer-logo {
  max-width: 320px;
}

footer .logo-info .footer-logo img {
  width: 100%;
}

footer .logo-info .social-networks {
  display: flex;
  gap: 16px;
}

footer .logo-info .social-networks a {
  display: flex;
}

footer .logo-info .social-networks a img {
  max-width: 32px;
  width: 100%;
  height: auto;
}

@media (max-width: 1439px) {
  footer .content {
    grid-template-columns: auto;
    gap: 1rem;
  }
}

@media (max-width: 767px) {
  footer {
    gap: 16px;
    flex-direction: column
  }

  footer .logo-info {
    align-items: flex-start;
  }
}

@media (max-width: 425px) {

  caption,
  .section-title {
    font-size: 50px;
  }
}

@media (max-width: 374px) {
  .hero-content .book-btn {
    font-size: 24px;
    height: auto;
  }

  .hero-content .hero-subtitle {
    font-size: 50px;
  }

  .hero-content .hero-title {
    font-size: 30px;
  }

  caption,
  .section-title {
    font-size: 36px;
  }

  .about-description {
    font-size: 22px;
  }
}
</style>